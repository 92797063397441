const hasNonEmptyValue = (data) => {
    return data?.some(item => item.value !== null && item.value !== undefined && item.value !== "");
};
const transformData = (inputData) => {
    return inputData?.reduce((result, item) => {
        if (item.value !== null && item.value !== undefined && item.value !== "") {
            result[item.name] = item.value;
        }
        return result;
    }, {});
};
const transQueryData = (inputData) => {
    const keyValuePairs = inputData?.filter(item => item.value !== null && item.value !== undefined && item.value !== "")
        .map(item => `${encodeURIComponent(item.name)}=${encodeURIComponent(item.value)}`);
    return keyValuePairs?.join("&") || "";
};
const transformData1 = (_obj) => {
    let obj = transformData(_obj);
    let stringResult = "{";
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            stringResult += `${key}:'${obj[key]}',`;
        }
    }
    stringResult = stringResult.slice(0, -1); // 移除末尾的逗号
    stringResult += "}";
    return stringResult;
};
const transformData2 = (_obj) => {
    let obj = transformData(_obj);
    let transformedData = "{";
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            transformedData += `:${key} "${obj[key]}" `;
        }
    }
    transformedData = transformedData.trim() + "}";
    return transformedData;
};
const transformData3 = (_obj) => {
    let obj = transformData(_obj);
    const keyValuePairs = Object.entries(obj)
        .map(([key, value]) => `${key}="${value}"`)
        .join(", ");
    return `(${keyValuePairs})`;
};
const capitalizeFirstLetter = (str) => {
    // console.log(str.charAt(0).toUpperCase() + str.slice(1));
    return str.charAt(0).toUpperCase() + str.slice(1);
};
//todo:这里默认POST请求数据类型是application/json,不包含表单数据application/x-www-form-urlencoded和字符串数据text/plain
//todo:这里的body params（post）的类型都是string，如果有number，json还需要做兼容
export default function getTemplate(val) {
    let { path, method, params, hostName } = val;
    let url = hostName + path;
    let dataTemp = "";
    if (method === "get") {
        dataTemp = transQueryData(params);
    }
    else if (method === "post") {
        dataTemp = JSON.stringify(transformData(params) || "");
    }
    //bodyParams: post; queryParams：get
    const template = {
        shell: {
            curl: `curl --request ${method.toUpperCase()} \\
     --url ${method === "post" && hasNonEmptyValue(params) ? `'${url}?${dataTemp}'` : `${url}`} \\${method === "post" ? "\n     --header 'accept: application/json' \\" : ""}
     --header 'content-type: application/json' \\${method === "post" && hasNonEmptyValue(params) ? `\n     --data '${dataTemp}'` : ""}
`,
            httpie: `${method === "post" && hasNonEmptyValue(params) ? `echo '${dataTemp}' | \\ \n  ` : ""}http ${method.toUpperCase()} ${method === "get" && hasNonEmptyValue(params) ? `'${url}?${dataTemp}'` : `${url}`} \\
  accept:application/json \\
  ${method === "post" ? "content-type:application/json" : ""}
    `
        },
        node: {
            // api: `
            // const sdk=require('api')()
            // `,
            axios: `const axios = require('axios');

const options = {
  method: '${method.toUpperCase()}',
  url: '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}',
  headers: {accept: 'application/json', ${method === "post" ? "'content-type': 'application/json'" : ""}},
  ${method === "post" && hasNonEmptyValue(params) ? `data: ${transformData1(params)}` : ""}
};

axios
  .request(options)
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });
`,
            nodeFetch: `const fetch = require('node-fetch');

const url = '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}';
const options = {
  method: '${method.toUpperCase()}',
  headers: {accept: 'application/json', ${method === "post" ? "'content-type': 'application/json'" : ""}},
  ${method === "post" && hasNonEmptyValue(params) ? `body: JSON.stringify(${transformData1(params)})` : ""}
};

fetch(url, options)
  .then(res => res.json())
  .then(json => console.log(json))
  .catch(err => console.error('error:' + err));
    
`,
            http: `const http = require('https');

const options = {
  method: '${method.toUpperCase()}',
  hostname: '${hostName}',
  port: null,
  path: '${path}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}',
  headers: {
    accept: 'application/json',
    ${method === "post" ? "'content-type': 'application/json'" : ""}
  }
};

const req = http.request(options, function (res) {
  const chunks = [];

  res.on('data', function (chunk) {
    chunks.push(chunk);
  });

  res.on('end', function () {
    const body = Buffer.concat(chunks);
    console.log(body.toString());
  });
});

${method === "post" && hasNonEmptyValue(params) ? `req.write(JSON.stringify(${transformData1(params)}))` : ""}
req.end();
    `,
            request: `const request = require('request');

const options = {
  method: '${method.toUpperCase()}',
  url: '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}',
  headers: {accept: 'application/json', ${method === "post" ? "'content-type': 'application/json'" : ""}},
  ${method === "post" && hasNonEmptyValue(params) ? `body: ${transformData1(params)}` : ""}
  ${method === "post" && hasNonEmptyValue(params) ? "json: true" : ""}
};

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});
`
        },
        ruby: `require 'uri'
require 'net/http'

url = URI("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::${capitalizeFirstLetter(method)}.new(url)
request["accept"] = 'application/json'
${method === "post" ? "request[\"content-type\"] = 'application/json'" : ""}
${method === "post" && hasNonEmptyValue(params) ? `request.body = JSON.dump(${dataTemp})` : ""}
response = http.request(request)
puts response.read_body   
  `,
        php: {
            curl: `<?php

$curl = curl_init();

curl_setopt_array($curl, [
  CURLOPT_URL => "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "${method.toUpperCase()}",
  ${method === "post" && hasNonEmptyValue(params) ? `CURLOPT_POSTFIELDS => '${dataTemp}'` : ""}
  CURLOPT_HTTPHEADER => [
    "accept: application/json"${method === "post" ? ", \"content-type: application/json\"" : ""}
  ],
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}
    `,
            guzzle: `<?php
require_once('vendor/autoload.php');

$client = new \\GuzzleHttp\\Client();

$response = $client->request('${method.toUpperCase()}', '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}', [
  ${method === "post" && hasNonEmptyValue(params) ? `'body' => '${dataTemp}',` : ""}
  'headers' => [
    'accept' => 'application/json',
    ${method === "post" ? "\'content-type\'=> \'application/json\'" : ""}
  ],
]);

echo $response->getBody();
`
        },
        python: `import requests

url = "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}"

${method === "post" && hasNonEmptyValue(params) ? `payload =${dataTemp}` : ""}
headers = {
    "accept": "application/json",
    ${method === "post" ? "\"content-type\": \"application/json\"" : ""}
}

response = requests.${method}(url, json=payload, headers=headers)

print(response.text)
  `,
        c: `CURL *hnd = curl_easy_init();

curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "${method.toUpperCase()}");
curl_easy_setopt(hnd, CURLOPT_WRITEDATA, stdout);
curl_easy_setopt(hnd, CURLOPT_URL, "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}");

struct curl_slist *headers = NULL;
headers = curl_slist_append(headers, "accept: application/json");
${method === "post" ? "headers = curl_slist_append(headers, \"content-type: application/json\");" : ""}
curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

${method === "post" && hasNonEmptyValue(params) ? `curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, "${dataTemp?.replace(/"/g, "\\\"")}");` : ""}

CURLcode ret = curl_easy_perform(hnd);
  `,
        cpp: `CURL *hnd = curl_easy_init();

curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "${method.toUpperCase()}");
curl_easy_setopt(hnd, CURLOPT_WRITEDATA, stdout);
curl_easy_setopt(hnd, CURLOPT_URL, "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}");

struct curl_slist *headers = NULL;
headers = curl_slist_append(headers, "accept: application/json");
${method === "post" ? "headers = curl_slist_append(headers, \"content-type: application/json\");" : ""}
curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

${method === "post" && hasNonEmptyValue(params) ? `curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, "${dataTemp?.replace(/"/g, "\\\"")}");` : ""}

CURLcode ret = curl_easy_perform(hnd);
  `,
        csharp: `using RestSharp;


var options = new RestClientOptions("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}");
var client = new RestClient(options);
var request = new RestRequest("");
request.AddHeader("accept", "application/json");
${method === "post" ? "request.AddHeader(\"content-type\", \"application/json\");" : ""}
${method === "post" && hasNonEmptyValue(params) ? `request.AddJsonBody("${dataTemp?.replace(/"/g, "\\\"")}",false);` : ""}
var response = await client.${capitalizeFirstLetter(method)}Async(request);

Console.WriteLine("{0}", response.Content);

  `,
        clojure: `(require '[clj-http.client :as client])

(client/${method} "${url}" {${method === "post" ? ":content-type :json" : ""}${hasNonEmptyValue(params) ? method === "get" ? `:query-params ${transformData2(params)}` : ` :form-params ${transformData2(params)}` : ""} :accept :json})
  `,
        go: `package main

import (
\t"fmt"
${method === "post" && hasNonEmptyValue(params) ? "\t\"strings\"" : ""}
\t"net/http"
\t"io"
)

func main() {

\turl := "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}"

${method === "post" && hasNonEmptyValue(params) ? `payload := strings.NewReader("${dataTemp?.replace(/"/g, "\\\"")}");` : ""}

\treq, _ := http.NewRequest("${method.toUpperCase()}", url, ${method === "post" && hasNonEmptyValue(params) ? "payload" : "nil"})

\treq.Header.Add("accept", "application/json")
${method === "post" ? "req.Header.Add(\"content-type\", \"application/json\")" : ""}

\tres, _ := http.DefaultClient.Do(req)

\tdefer res.Body.Close()
\tbody, _ := io.ReadAll(res.Body)

\tfmt.Println(string(body))

}
  `,
        http: `${method.toUpperCase()} ${path}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""} HTTP/1.1
Accept: application/json
${method === "post" ? "Content-Type: application/json" : ""}
Host: ${hostName}
${method === "post" && hasNonEmptyValue(params) ? `Content-Length: ${dataTemp.length}` : ""}

${method === "post" && hasNonEmptyValue(params) ? `${dataTemp}` : ""}
  `,
        java: {
            asyncHttp: `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("${method.toUpperCase()}", "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")
  .setHeader("accept", "application/json")
  ${method === "post" ? ".setHeader(\"content-type\", \"application/json\")" : ""}
  ${method === "post" && hasNonEmptyValue(params) ? `.setBody("${dataTemp?.replace(/"/g, "\\\"")}")` : ""}
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();
    `,
            javaNetHttp: `HttpRequest request = HttpRequest.newBuilder()
  .uri(URI.create("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}"))
  .header("accept", "application/json")
  ${method === "post" ? ".header(\"content-type\", \"application/json\")" : ""}
  .method("${method.toUpperCase()}", HttpRequest.BodyPublishers.${method === "post" && hasNonEmptyValue(params) ? `ofString("${dataTemp?.replace(/"/g, "\\\"")}")` : "noBody()"})
  .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());
    `,
            okhttp: `OkHttpClient client = new OkHttpClient();

${method === "post" && hasNonEmptyValue(params) ? `MediaType mediaType = MediaType.parse("application/json");` : ""}
${method === "post" && hasNonEmptyValue(params) ? `RequestBody body = RequestBody.create(mediaType,"${dataTemp?.replace(/"/g, "\\\"")}");` : ""}
Request request = new Request.Builder()
  .url("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")
  .${method}(${method === "post" ? hasNonEmptyValue(params) ? "body" : "null" : ""})
  .addHeader("accept", "application/json")
  ${method === "post" ? ".addHeader(\"content-type\", \"application/json\")" : ""}
  .build();

Response response = client.newCall(request).execute();
    `,
            unirest: `HttpResponse<String> response = Unirest.${method}("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")
  .header("accept", "application/json")
  ${method === "post" ? ".header(\"content-type\", \"application/json\")" : ""}
  ${method === "post" && hasNonEmptyValue(params) ? `.body("${dataTemp?.replace(/"/g, "\\\"")}")` : ""}
  .asString();
    `
        },
        javascript: {
            axios: `import axios from 'axios';

const options = {
  method: '${method.toUpperCase()}',
  url: '${url}',
  headers: {accept: 'application/json'${method === "post" ? ", 'content-type': 'application/json'" : ""}},
  ${hasNonEmptyValue(params) ? method === "post" ? `data: ${transformData1(params)}` : `params: ${transformData1(params)}` : ""}
};

axios
  .request(options)
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });
    `,
            fetch: `const options = {
method: '${method.toUpperCase()}',
headers: {accept: 'application/json'${method === "post" ? ", 'content-type': 'application/json'" : ""},
${method === "post" && hasNonEmptyValue(params) ? `body: JSON.stringify(${transformData1(params)})` : ""}
};

fetch('${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));
    `,
            jquery: `const settings = {
  async: true,
  crossDomain: true,
  url: '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}',
  method: '${method.toUpperCase()}',
  headers: {
    accept: 'application/json'
    ${method === "post" ? "'content-type': 'application/json'" : ""}
  },
  ${method === "post" && hasNonEmptyValue(params) ? `processData: false,` : ""}
  ${method === "post" && hasNonEmptyValue(params) ? `data: '${dataTemp}'` : ""}
};

$.ajax(settings).done(function (response) {
  console.log(response);
});
`,
            xmlHttpRequest: `${method === "get" ? "const data = null;" : ""}
${method === "post" ? hasNonEmptyValue(params) ? `const data = JSON.stringify(${transformData1(params)});` : "const data = JSON.stringify(undefined);" : ""}

const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener('readystatechange', function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open('${method.toUpperCase()}', '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}');
xhr.setRequestHeader('accept', 'application/json');
${method === "post" ? "xhr.setRequestHeader('content-type', 'application/json');" : ""}

xhr.send(data);
    `
        },
        json: `No JSON body`,
        kotlin: `val client = OkHttpClient()

${method === "post" && hasNonEmptyValue(params) ? "val mediaType = MediaType.parse(\"application/json\")" : ""}
${method === "post" && hasNonEmptyValue(params) ? `val body = RequestBody.create(mediaType, "${dataTemp?.replace(/"/g, "\\\"")}")` : ""}
val request = Request.Builder()
  .url("${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")
  .${method}(${method === "post" ? hasNonEmptyValue(params) ? "body" : "null" : ""})
  .addHeader("accept", "application/json")
  ${method === "post" ? ".addHeader(\"content-type\", \"application/json\")" : ""}
  .build()

val response = client.newCall(request).execute()
  `,
        objectiveC: `#import <Foundation/Foundation.h>

NSDictionary *headers = @{ @"accept": @"application/json"${method === "post" ? ",\n" +
            "                           @\"content-type\": @\"application/json\"" : ""} };

${method === "post" && hasNonEmptyValue(params) ? `NSDictionary *parameters = @${dataTemp.replace(/"(\w+)":\s*"([^"]+)"/g, "@\"$1\": @\"$2\"")};` : ""}
${method === "post" && hasNonEmptyValue(params) ? "NSData *postData = [NSJSONSerialization dataWithJSONObject:parameters options:0 error:nil];" : ""}

NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:[NSURL URLWithString:@"${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}"]
                                                       cachePolicy:NSURLRequestUseProtocolCachePolicy
                                                   timeoutInterval:10.0];
[request setHTTPMethod:@"${method.toUpperCase()}"];
[request setAllHTTPHeaderFields:headers];
${method === "post" && hasNonEmptyValue(params) ? "[request setHTTPBody:postData];" : ""}

NSURLSession *session = [NSURLSession sharedSession];
NSURLSessionDataTask *dataTask = [session dataTaskWithRequest:request
                                            completionHandler:^(NSData *data, NSURLResponse *response, NSError *error) {
                                                if (error) {
                                                    NSLog(@"%@", error);
                                                } else {
                                                    NSHTTPURLResponse *httpResponse = (NSHTTPURLResponse *) response;
                                                    NSLog(@"%@", httpResponse);
                                                }
                                            }];
[dataTask resume];
  `,
        oCaml: `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}" in
${method === "post" ?
            "let headers = Header.add_list (Header.init ()) [\n" +
                "  (\"accept\", \"application/json\");\n" +
                "  (\"content-type\", \"application/json\");\n" +
                "] in" :
            "let headers = Header.add (Header.init ()) \"accept\" \"application/json\" in"}
${method === "post" && hasNonEmptyValue(params) ? `let body = Cohttp_lwt_body.of_string "${dataTemp?.replace(/"/g, "\\\"")}" in` : ""}    

Client.call ~headers ${method === "post" && hasNonEmptyValue(params) ? `~body ` : ""}\`${method.toUpperCase()} uri
>>= fun (res, body_stream) ->
  (* Do stuff with the result *)
`,
        powerShell: {
            invokeRestMethod: `
  $headers=@{}
  $headers.Add("accept", "application/json")
  ${method === "post" ? "$headers.Add(\"content-type\", \"application/json\")" : ""}
  $response = Invoke-RestMethod -Uri '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}' -Method ${method.toUpperCase()} -Headers $headers ${method === "post" && hasNonEmptyValue(params) ? `-ContentType 'application/json' -Body '${dataTemp}'` : ""}
  `,
            invokeWebRequest: `
  $headers=@{}
  $headers.Add("accept", "application/json")
  ${method === "post" ? "$headers.Add(\"content-type\", \"application/json\")" : ""}
  $response = Invoke-WebRequest -Uri '${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}' -Method ${method.toUpperCase()} -Headers $headers ${method === "post" && hasNonEmptyValue(params) ? `-ContentType 'application/json' -Body '${dataTemp}'` : ""}
  `
        },
        r: `
library(httr)

url <- "${url}"

${method === "post" && hasNonEmptyValue(params) ? `payload <- "${dataTemp?.replace(/"/g, "\\\"")}"` : ""}
${method === "post" && hasNonEmptyValue(params) ? "encode <- \"json\"" : ""}
${method === "get" && hasNonEmptyValue(params) ? `queryString <- list${transformData3(params)}` : ""}
response <- VERB("${method.toUpperCase()}", url, ${hasNonEmptyValue(params) ? method === "post" ? "body = payload, encode = encode," : "query = queryString," : ""} ${method === "post" ? "content_type(\"application/json\")," : "content_type(\"application/octet-stream\"),"} accept("application/json"))

content(response, "text")
`,
        swift: `import Foundation

let headers = ["accept": "application/json"${method === "post" ? ", \"content-type\": \"application/json\"" : ""}]

${method === "post" && hasNonEmptyValue(params) ? `let parameters = ${"[" +
            Object.entries(transformData(params)).map(([key, value]) => `"${key}": "${value}"`).join(", ") +
            "]"} as [String : Any]` : ""}

${method === "post" && hasNonEmptyValue(params) ? "let postData = JSONSerialization.data(withJSONObject: parameters, options: [])" : ""}

let request = NSMutableURLRequest(url: NSURL(string: "${url}${method === "get" && hasNonEmptyValue(params) ? `?${dataTemp}` : ""}")! as URL,
                                        cachePolicy: .useProtocolCachePolicy,
                                    timeoutInterval: 10.0)
request.httpMethod = "${method.toUpperCase()}"
request.allHTTPHeaderFields = headers
${method === "post" && hasNonEmptyValue(params) ? "request.httpBody = postData as Data" : ""}

let session = URLSession.shared
let dataTask = session.dataTask(with: request as URLRequest, completionHandler: { (data, response, error) -> Void in
  if (error != nil) {
    print(error as Any)
  } else {
    let httpResponse = response as? HTTPURLResponse
    print(httpResponse)
  }
})

dataTask.resume()
  `
    };
    return (language, library) => {
        if (!(language in template)) {
            return undefined;
        }
        else if (typeof template[language] === "string") {
            return template[language];
        }
        else if (library && Object.keys(template[language]).indexOf(library) === -1) {
            return undefined;
        }
        else if (library) {
            return template[language][library];
        }
    };
}
;
