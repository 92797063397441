import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./DashboardPage.scss";
import { Layout } from "antd";
import axios from "../utils/axios";
import { memo, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import * as echarts from "echarts/core";
import { getToken } from "../utils/token";
const { Header, Sider, Content } = Layout;
var MEMBER_LEVEL;
(function (MEMBER_LEVEL) {
    MEMBER_LEVEL[MEMBER_LEVEL["FREE"] = 0] = "FREE";
    MEMBER_LEVEL[MEMBER_LEVEL["PRO"] = 1] = "PRO";
    MEMBER_LEVEL[MEMBER_LEVEL["ENTERPRISE"] = 2] = "ENTERPRISE";
})(MEMBER_LEVEL || (MEMBER_LEVEL = {}));
var CHART_TYPE;
(function (CHART_TYPE) {
    CHART_TYPE[CHART_TYPE["HOUR"] = 0] = "HOUR";
    CHART_TYPE[CHART_TYPE["DAY"] = 1] = "DAY";
    CHART_TYPE[CHART_TYPE["WEEK"] = 2] = "WEEK";
    CHART_TYPE[CHART_TYPE["MONTH"] = 3] = "MONTH";
})(CHART_TYPE || (CHART_TYPE = {}));
const initChartData = {
    x: [],
    y: []
};
const [initDailyData, initWeeklyData, initMonthData, initHourData] = Array(4).fill(initChartData);
function useMyCustomHook() {
    const myRef = useRef(null);
    // do something with the ref, e.g. adding event listeners
    return { ref: myRef };
}
const MemberLevelFlag = memo((props) => {
    const [isHover, setIsHover] = useState(false);
    const { memberLevel } = props;
    const px2rem = (px) => {
        return px / 16 + "rem";
    };
    let bg_0, bg_50, bg_100, color_100;
    if (memberLevel === MEMBER_LEVEL.FREE) {
        bg_0 = "linear-gradient(111deg, #DEFFFF 0%, rgba(121, 229, 229, 0.48) 100%)";
        bg_50 = "linear-gradient(111deg, #DEFFFF 0%, rgba(121, 229, 229, 0.48) 60%)";
        bg_100 = "linear-gradient(111deg, rgba(121, 229, 229, 0.48) 0%, #DEFFFF 100%)";
        color_100 = "#FFFFFF";
    }
    else if (memberLevel === MEMBER_LEVEL.PRO) {
        bg_0 = "linear-gradient(291deg, #CC8AFF 0%, #FCDAFF 100%)";
        bg_50 = "linear-gradient(291deg, #CC8AFF 0%, #FCDAFF 60%)";
        bg_100 = "linear-gradient(291deg, #FCDAFF 0%, #CC8AFF 100%)";
        color_100 = "linear-gradient(180deg, #8900DF 0%, #E1B1FF 100%)";
    }
    else if (memberLevel === MEMBER_LEVEL.ENTERPRISE) {
        bg_0 = "linear-gradient(111deg, #FFFFDF 0%, #FFF1DF 100%)";
        bg_50 = "linear-gradient(111deg, #FFFFDF 0%, #FFF1DF 60%)";
        bg_100 = "linear-gradient(111deg, #FFF1DF 0%, #FFFFDF 100%)";
        color_100 = "linear-gradient(180deg, #D09800 0%, #FFD152 100%)";
    }
    const hoverFreeAnimation = keyframes `
    0% {
      font-size: ${px2rem(20)};
      color: #242424;
      background: ${bg_0};
    }
    50% {
      background: ${bg_50};
      box-shadow: 0 1px 2px 0 rgba(136, 136, 136, 0.24), 0 3px 4px 0 rgba(136, 136, 136, 0.16), 0 5px 12px 0 rgba(136, 136, 136, 0.08);
    }
    100% {
      font-size: ${px2rem(24)};
      color: ${color_100};
      background: ${bg_100};
    }
  `;
    const hoverFreeAnimation1 = keyframes `
    100% {
      font-size: ${px2rem(20)};
      color: #242424;
      background: ${bg_0};
    }
    50% {
      background: ${bg_50};
      box-shadow: 0 1px 2px 0 rgba(136, 136, 136, 0.24), 0 3px 4px 0 rgba(136, 136, 136, 0.16), 0 5px 12px 0 rgba(136, 136, 136, 0.08);
    }
    0% {
      font-size: ${px2rem(24)};
      color: ${color_100};
      background: ${bg_100};
    }
  `;
    const hoverTextAnimation = keyframes `
    //注意background、-webkit-background-clip、-webkit-text-fill-color这三条属性一定要粘在一起，且background在其他两条上面
    0% {
      background: #242424;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    100% {
      background: ${color_100};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `;
    const hoverTextAnimation1 = keyframes `
    100% {
      background: #242424;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    0% {
      background: ${color_100};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  `;
    const AnimatedComponent = styled.div `
    animation-name: ${isHover ? hoverFreeAnimation : hoverFreeAnimation1};
    animation-timing-function: linear;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  `;
    const AnimatedSpan = styled.span `
    animation-name: ${isHover ? hoverTextAnimation : hoverTextAnimation1};
    animation-timing-function: linear;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  `;
    if (memberLevel === MEMBER_LEVEL.FREE) {
        return _jsx(AnimatedComponent, { className: "free", onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), children: "Free" });
    }
    else if (memberLevel === MEMBER_LEVEL.PRO) {
        return _jsx(AnimatedComponent, { className: "pro", onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), children: _jsx(AnimatedSpan, { children: "Pro" }) });
    }
    else if (memberLevel === MEMBER_LEVEL.ENTERPRISE) {
        return _jsx(AnimatedComponent, { className: "enterprise", onMouseEnter: () => setIsHover(true), onMouseLeave: () => setIsHover(false), children: _jsx(AnimatedSpan, { children: "Enterprise" }) });
    }
    else {
        return _jsx(_Fragment, {});
    }
});
export default function DashboardPage() {
    const classPrefix = "dashboard";
    const [memberLevel, setMemberLevel] = useState(MEMBER_LEVEL.ENTERPRISE);
    const [userData, setUserData] = useState();
    const [chartData, setChartData] = useState({
        hourlyData: initHourData,
        dailyData: initDailyData,
        weeklyData: initWeeklyData,
        monthlyData: initMonthData
    });
    const [chartType, setChartType] = useState(CHART_TYPE.HOUR);
    const user_id = getToken("account")?.user_id;
    const { ref: eChartsRef } = useMyCustomHook();
    useEffect(() => {
        const element = eChartsRef.current;
        const myChart = echarts.init(element);
        // console.log(hourlyData.x, dailyData.x, weeklyData.x, monthlyData.x);
        const { hourlyData, dailyData, weeklyData, monthlyData } = chartData;
        const option = {
            tooltip: {
                trigger: "axis",
                axisPointer: { type: "cross" }
            },
            xAxis: {
                axisLine: {
                    show: true
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    textStyle: {
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: "bold"
                    }
                },
                name: "date",
                type: "category",
                data: chartType === CHART_TYPE.HOUR ? hourlyData.x : chartType === CHART_TYPE.DAY ? dailyData.x : chartType === CHART_TYPE.WEEK ? weeklyData.x : monthlyData.x
            },
            yAxis: {
                splitLine: {
                    show: true
                },
                type: "value",
                name: "total",
                axisLabel: {
                    formatter: "{value}",
                    textStyle: {
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: "bold"
                    },
                    margin: 30
                }
            },
            series: {
                type: "line",
                // smooth: true,
                symbolSize: 4,
                lineStyle: {
                    width: 1.63
                },
                color: "#225353",
                data: chartType === CHART_TYPE.HOUR ? hourlyData.y : chartType === CHART_TYPE.DAY ? dailyData.y : chartType === CHART_TYPE.WEEK ? weeklyData.y : monthlyData.y
            }
        };
        myChart.setOption(option);
    }, [eChartsRef, chartData, chartType]);
    useEffect(() => {
        getUserData();
        Promise.all([
            getHourlyRecord(),
            getDailyRecord(),
            getWeeklyRecord(),
            getMonthlyRecord()
        ]).then(([hourlyData, dailyData, weeklyData, monthlyData]) => {
            setChartData({
                hourlyData,
                dailyData,
                weeklyData,
                monthlyData
            });
        });
    }, []);
    const getUserData = () => {
        const doRequest = axios.get("statistics/using-statistic/get-user", { params: { user_id } });
        doRequest.then((res) => {
            if (res?.status === 200) {
                setUserData(res?.data?.data);
            }
        });
    };
    const getMonthlyRecord = () => {
        return axios.get("/statistics/using-statistic/monthly-record", { params: { user_id } })
            .then((res) => {
            let monthly_list = res.data.data;
            const new_array = {
                x: [],
                y: []
            };
            Array.isArray(monthly_list) && monthly_list.reverse().map((item) => {
                new_array?.x.push(item.month_date);
                new_array?.y.push(item.month_total);
            });
            return new_array;
        });
    };
    const getWeeklyRecord = () => {
        return axios.get("/statistics/using-statistic/weekly-record", { params: { user_id } })
            .then((res) => {
            let weekly_list = res.data.data;
            const new_array = {
                x: [],
                y: []
            };
            Array.isArray(weekly_list) && weekly_list.reverse().map((item) => {
                new_array?.x.push(item.week_date);
                new_array?.y.push(item.week_total);
            });
            return new_array;
        });
    };
    const getDailyRecord = () => {
        return axios.get("/statistics/using-statistic/daily-record", { params: { user_id } })
            .then((res) => {
            let daily_list = res.data.data;
            const new_array = {
                x: [],
                y: []
            };
            Array.isArray(daily_list) && daily_list.reverse().map((item) => {
                new_array?.x.push(item.day_date);
                new_array?.y.push(item.day_total);
            });
            return new_array;
        });
    };
    const getHourlyRecord = () => {
        return axios.get("/statistics/using-statistic/hourly-record", { params: { user_id } })
            .then((res) => {
            let hourly_list = res.data.data;
            const new_array = {
                x: [],
                y: []
            };
            Array.isArray(hourly_list) && hourly_list.reverse().map((item) => {
                new_array?.x.push(item.hour_date);
                new_array?.y.push(item.hour_total);
            });
            return new_array;
        });
    };
    return (_jsxs("div", { className: classPrefix, children: [_jsx("section", { className: "advertisement" }), _jsxs("section", { className: "api-plans", children: [_jsxs("div", { className: "left", children: [_jsx("span", { className: "fs24 fw700 fmc1", children: "API Plans:" }), _jsx("div", { className: "btn-bar", children: _jsx(MemberLevelFlag, { memberLevel: memberLevel }) })] }), _jsx("button", { className: "right fs20 fmc1 btn-color", onClick: () => setMemberLevel(prevState => (((prevState + 1) % 3) + 3) % 3), children: "Upgrade / Extend Plans" })] }), _jsxs("div", { className: "usage", children: ["Usage:", userData?.query_cnt, "/1,000,000 queries"] }), _jsxs("div", { className: "chart-card", children: [_jsxs("div", { className: "chart-card-header", children: [_jsx("h3", { children: "Daily Usage" }), _jsxs("div", { className: "chart-tabbar", children: [_jsx("button", { onClick: () => setChartType(CHART_TYPE.HOUR), className: `white btn-color ${chartType === CHART_TYPE.HOUR ? "btn-color-selected" : ""}`, children: "Hour" }), _jsx("button", { onClick: () => setChartType(CHART_TYPE.DAY), className: `white btn-color ${chartType === CHART_TYPE.DAY ? "btn-color-selected" : ""}`, children: "Day" }), _jsx("button", { onClick: () => setChartType(CHART_TYPE.WEEK), className: `white btn-color ${chartType === CHART_TYPE.WEEK ? "btn-color-selected" : ""}`, children: "Week" }), _jsx("button", { onClick: () => setChartType(CHART_TYPE.MONTH), className: `white btn-color ${chartType === CHART_TYPE.MONTH ? "btn-color-selected" : ""}`, children: "Month" })] })] }), _jsx("div", { ref: eChartsRef, className: "chart-core" })] })] }));
}
