import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import username_icon from "../../img/Register/username.svg";
import email_icon from "../../img/Register/Email.svg";
import password_icon from "../../img/Register/key.svg";
import unvisible_icon from "../../img/Register/contsee.svg";
import visible_icon from "../../img/Register/Visible.svg";
import verificationcode_icon from "../../img/Register/Verificationcode.svg";
import question_icon from "../../img/Register/question.svg";
import ReCAPTCHA from "react-google-recaptcha";
import "./Input.scss";
import { useEffect, useRef, useState } from "react";
// import Tooltip from "../Tooltip/Tooltip";
import axios from "../../utils/axios";
import toaster from "rsuite/toaster";
import Notification from "rsuite/Notification";
import Tooltip from "rsuite/Tooltip";
import Whisper from "rsuite/Whisper";
import "rsuite/toaster/styles/index.less";
import "rsuite/Notification/styles/index.less";
import "rsuite/Tooltip/styles/index.less";
const classPrefix = "FC";
function validate(validatableInput) {
    let isValid = true;
    if (validatableInput.required) {
        isValid = isValid && validatableInput.value.toString().trim().length !== 0;
    }
    return isValid;
}
var inputStatus;
(function (inputStatus) {
    inputStatus[inputStatus["normal"] = 0] = "normal";
    inputStatus[inputStatus["focus"] = 1] = "focus";
    inputStatus[inputStatus["error"] = 2] = "error";
    inputStatus[inputStatus["hover"] = 3] = "hover";
})(inputStatus || (inputStatus = {}));
export const UserNameInput = React.forwardRef(({ name }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        if (!inputRef?.current?.value) {
            setStatus(inputStatus.error);
            setShowError(true);
        }
        else {
            return true;
        }
    };
    const [status, setStatus] = useState(inputStatus.normal);
    const [showError, setShowError] = useState(false);
    const inputRef = useRef(null);
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    return (_jsxs("div", { className: `${classPrefix}-form-item`, children: [_jsx("div", { className: `${classPrefix}-form-item-label`, children: _jsx("label", { className: `${classPrefix}-form-item-required`, children: "Username" }) }), _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: _jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("span", { className: `${classPrefix}-input-wrapper ${classPrefix}-input-wrapper-status-${status === inputStatus.error ?
                                    "error" : status === inputStatus.focus || status === inputStatus.hover ?
                                    "focus" : ""}`, onMouseEnter: () => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.hover);
                                }, onMouseLeave: (e) => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.normal);
                                }, children: [_jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-prefix`, src: username_icon }), _jsx("input", { className: `${classPrefix}-input`, name: name, id: "username", maxLength: 128, ref: inputRef, onFocus: () => {
                                            !showError && setStatus(inputStatus.focus);
                                        }, onBlur: (e) => {
                                            if (!validate({ value: e.target.value, required: true })) {
                                                setShowError(true);
                                                setStatus(inputStatus.error);
                                            }
                                            else {
                                                setShowError(false);
                                                setStatus(inputStatus.normal);
                                            }
                                        } })] }) }) }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap", float: "left" }, children: showError && _jsx("div", { id: "username_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: "Please enter your username" }) }) })] })] }));
});
export const EmailInput = React.forwardRef(({ name }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        if (!inputRef?.current?.value) {
            setStatus(inputStatus.error);
            setShowError(true);
        }
        else if (validateEmail(inputRef?.current?.value)) {
            return true;
        }
        //不需要在这里校验邮箱格式是否正确，因为失去焦点会做校验（input输入后点击提交按钮）
        //需要做是否为空的校验是因为第一次不输入任何东西就点击提交
        // else if (!isEmail) {
        //     setStatus(inputStatus.error)
        //     setShowError(true)
        // }
    };
    const [status, setStatus] = useState(inputStatus.normal);
    const [showError, setShowError] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const inputRef = useRef(null);
    const validateEmail = (value) => {
        let flag = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value);
        setIsEmail(flag);
        return flag;
    };
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    return (_jsxs("div", { className: `${classPrefix}-form-item`, children: [_jsx("div", { className: `${classPrefix}-form-item-label`, children: _jsx("label", { className: `${classPrefix}-form-item-required`, children: "Email" }) }), _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: _jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("span", { className: `${classPrefix}-input-wrapper ${classPrefix}-input-wrapper-status-${status === inputStatus.error ?
                                    "error" : status === inputStatus.focus || status === inputStatus.hover ?
                                    "focus" : ""}`, onMouseEnter: () => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.hover);
                                }, onMouseLeave: (e) => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.normal);
                                }, children: [_jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-prefix`, src: email_icon }), _jsx("input", { className: `${classPrefix}-input`, maxLength: 128, name: name, ref: inputRef, onFocus: () => {
                                            !showError && setStatus(inputStatus.focus);
                                        }, onBlur: (e) => {
                                            setIsEmail(true);
                                            if (!validate({
                                                value: e.target.value,
                                                required: true
                                            }) || !validateEmail(e.target.value)) {
                                                setShowError(true);
                                                setStatus(inputStatus.error);
                                            }
                                            else {
                                                setShowError(false);
                                                setStatus(inputStatus.normal);
                                            }
                                        } })] }) }) }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: showError &&
                            _jsx("div", { id: "email_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: isEmail ? "Please enter your email address" : "The format of your email address is incorrect" }) }) })] })] }));
});
export const Password = React.forwardRef(({ name, title }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        if (!inputRef?.current?.value) {
            setStatus(inputStatus.error);
            setShowError(true);
        }
        else if (validatePsw(inputRef?.current?.value)) {
            return true;
        }
        // else if (!isPsw) {
        //     setStatus(inputStatus.error)
        //     setShowError(true)
        // }
    };
    const [status, setStatus] = useState(inputStatus.normal);
    const [showError, setShowError] = useState(false);
    const [isPsw, setIsPsw] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [passwdVisible, setPasswdVisible] = useState(false);
    const inputRef = useRef(null);
    const validatePsw = (value) => {
        let flag = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._])[0-9a-zA-Z!@#$%^&*,\\._]{8,12}$/.test(value);
        setIsPsw(flag);
        return flag;
    };
    // const showTooltipDebounce = useRef(debounce(() => setShowTooltip(true), 500))
    // const hideTooltipDebounce = useRef(debounce(() => setShowTooltip(false), 500))
    // const handleShowTooltip = () => {
    //     showTooltipDebounce.current()
    // }
    // const handleHideTooltip = () => {
    //     hideTooltipDebounce.current()
    // }
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    return (_jsxs("div", { className: `${classPrefix}-form-item`, children: [_jsx("div", { className: `${classPrefix}-form-item-label`, children: _jsxs("label", { className: `${classPrefix}-form-item-required`, children: [title || "Password", _jsx("div", { className: `${classPrefix}-form-item-tips`, children: _jsx(Whisper, { speaker: _jsxs(Tooltip
                                // style={{backgroundColor: '#FFFFDF', color: '#242424'}}
                                , { children: ["1. Contains at least one digit (0-9).", _jsx("br", {}), "2. Contains at least one uppercase letter (A-Z).", _jsx("br", {}), "3. Contains at least one lowercase letter (a-z).", _jsx("br", {}), "4. Contains at least one special character from the set !@#$%^&*,._.", _jsx("br", {}), "5. Has a total length of 8 to 12 characters.", _jsx("br", {})] }), placement: "top", trigger: "hover", controlId: "control-id-hover", children: _jsx("img", { src: question_icon, className: `${classPrefix}-form-item-tips-img` }) }) })] }) }), _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: _jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("span", { className: `${classPrefix}-input-wrapper ${classPrefix}-input-wrapper-status-${status === inputStatus.error ?
                                    "error" : status === inputStatus.focus || status === inputStatus.hover ?
                                    "focus" : ""}`, onMouseEnter: () => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.hover);
                                }, onMouseLeave: (e) => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.normal);
                                }, children: [_jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-prefix`, src: password_icon }), _jsx("input", { className: `${classPrefix}-input`, maxLength: 128, name: name, type: passwdVisible ? "text" : "password", ref: inputRef, onFocus: () => {
                                            !showError && setStatus(inputStatus.focus);
                                        }, onBlur: (e) => {
                                            setIsPsw(true);
                                            if (!validate({
                                                value: e.target.value,
                                                required: true
                                            }) || !validatePsw(e.target.value)) {
                                                setShowError(true);
                                                setStatus(inputStatus.error);
                                            }
                                            else {
                                                setShowError(false);
                                                setStatus(inputStatus.normal);
                                            }
                                        } }), _jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-suffix`, src: !passwdVisible ? visible_icon : unvisible_icon, onClick: () => setPasswdVisible(!passwdVisible) })] }) }) }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: showError &&
                            _jsx("div", { id: "password_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: isPsw ? "Please enter your password" : "The password does not meet the requirements" }) }) })] })] }));
});
export const ConfirmPasswd = React.forwardRef(({ name, passwdValue }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        if (!inputRef?.current?.value) {
            setStatus(inputStatus.error);
            setShowError(true);
        }
        else if ((inputRef?.current?.value === passwdValue)) {
            return true;
        }
        // else if (inputRef?.current?.value !== passwdValue) {
        //     setStatus(inputStatus.error)
        //     setShowError(true)
        // }
    };
    const [status, setStatus] = useState(inputStatus.normal);
    const inputRef = useRef(null);
    const [showError, setShowError] = useState(false);
    const [passwdVisible, setPasswdVisible] = useState(false);
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    return (_jsxs("div", { className: `${classPrefix}-form-item`, children: [_jsx("div", { className: `${classPrefix}-form-item-label`, children: _jsx("label", { className: `${classPrefix}-form-item-required`, children: "Confirm Password" }) }), _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: _jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("span", { className: `${classPrefix}-input-wrapper ${classPrefix}-input-wrapper-status-${status === inputStatus.error ?
                                    "error" : status === inputStatus.focus || status === inputStatus.hover ?
                                    "focus" : ""}`, onMouseEnter: () => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.hover);
                                }, onMouseLeave: (e) => {
                                    status !== inputStatus.focus && !showError && setStatus(inputStatus.normal);
                                }, children: [_jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-prefix`, src: password_icon }), _jsx("input", { className: `${classPrefix}-input`, maxLength: 128, name: name, type: passwdVisible ? "text" : "password", ref: inputRef, onFocus: () => {
                                            !showError && setStatus(inputStatus.focus);
                                        }, onBlur: (e) => {
                                            if (!validate({
                                                value: e.target.value,
                                                required: true
                                            }) || (e.target.value !== passwdValue)) {
                                                setShowError(true);
                                                setStatus(inputStatus.error);
                                            }
                                            else {
                                                setShowError(false);
                                                setStatus(inputStatus.normal);
                                            }
                                        } }), _jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-suffix`, src: !passwdVisible ? visible_icon : unvisible_icon, onClick: () => setPasswdVisible(!passwdVisible) })] }) }) }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: showError &&
                            _jsx("div", { id: "email_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: (inputRef?.current?.value &&
                                        inputRef?.current?.value !== passwdValue) ?
                                        "The two passwords are inconsistent" :
                                        "Please enter your confirm password" }) }) })] })] }));
});
export const Verification = React.forwardRef(({ name, callback }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        if (!inputRef?.current?.value) {
            setStatus(inputStatus.error);
            setShowError(true);
        }
        else {
            return true;
        }
        // else if (inputRef?.current?.value !== passwdValue) {
        //     setStatus(inputStatus.error)
        //     setShowError(true)
        // }
    };
    const [status, setStatus] = useState(inputStatus.normal);
    const [showError, setShowError] = useState(false);
    const inputRef = useRef(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    useEffect(() => {
        if (remainingTime > 0) {
            //1秒➖1，用于记1秒的时间，1秒后重新注册timer，记得清除旧的timer
            const timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
        else {
            setIsButtonDisabled(false);
        }
    }, [remainingTime]);
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    const sendCode = () => {
        if (remainingTime > 0)
            return;
        const data = callback?.();
        if (!data)
            return;
        let doRequest = axios.post("nft-user/nft/send-verification-code", data);
        doRequest.then((res) => {
            if (res.status === 200 && res?.data?.message) {
                //间隔10分钟可以给同一个邮箱发送验证码（前端不做验证），间隔60秒可以给不同邮箱发送验证码（计时器）,先设置计时器再发送请求
                setRemainingTime(60);
                setIsButtonDisabled(true);
                toaster.push(_jsx(Notification, { type: "success", header: "success", children: res?.data?.message }), { placement: "topCenter", duration: 4500 });
            }
        });
    };
    return (_jsxs("div", { className: `${classPrefix}-form-item`, children: [_jsx("div", { className: `${classPrefix}-form-item-label`, children: _jsx("label", { className: `${classPrefix}-form-item-required`, children: "Verification Code" }) }), _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsxs("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("span", { className: `${classPrefix}-input-wrapper ${classPrefix}-input-wrapper-status-${status === inputStatus.error ?
                                        "error" : status === inputStatus.focus || status === inputStatus.hover ?
                                        "focus" : ""}`, onMouseEnter: () => {
                                        status !== inputStatus.focus && !showError && setStatus(inputStatus.hover);
                                    }, onMouseLeave: (e) => {
                                        status !== inputStatus.focus && !showError && setStatus(inputStatus.normal);
                                    }, children: [_jsx("img", { className: `${classPrefix}-input-icon ${classPrefix}-input-prefix`, src: verificationcode_icon }), _jsx("input", { className: `${classPrefix}-input`, maxLength: 128, name: name, ref: inputRef, onBlur: (e) => {
                                                if (!validate({ value: e.target.value, required: true })) {
                                                    setShowError(true);
                                                    setStatus(inputStatus.error);
                                                }
                                                else {
                                                    setShowError(false);
                                                    setStatus(inputStatus.normal);
                                                }
                                            } })] }) }), _jsx("div", { className: `
                             ${classPrefix}-form-item-control-btn
                             ${classPrefix}-form-item-control-btn-sm`, style: isButtonDisabled ? {
                                    backgroundColor: "rgba(34, 83, 83, .6)",
                                    cursor: "not-allowed"
                                } : {}, onClick: sendCode, children: isButtonDisabled ? `${remainingTime}s` : "Send code" })] }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: showError &&
                            _jsx("div", { id: "verification_code_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: "Please enter your verification code" }) }) })] })] }));
});
export const Therms = React.forwardRef(({ name }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const test = () => {
        //这里不要用 inputRef.current.value，不然永远是true
        if (!inputValue) {
            setFirstClick(true);
            // console.log(firstClick, !inputValue)
        }
        else {
            return true;
        }
    };
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(0);
    const [firstClick, setFirstClick] = useState(false);
    const handleInputClick = () => {
        inputRef.current?.focus();
    };
    // console.log(inputRef?.current?.value)
    return (_jsx("div", { className: `${classPrefix}-form-item`, children: _jsxs("div", { className: `${classPrefix}-form-item-control`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-input`, onClick: handleInputClick, children: _jsx("div", { className: `${classPrefix}-form-item-control-input-content`, children: _jsxs("label", { className: `${classPrefix}-checkbox-group`, children: [_jsx("span", { className: `${classPrefix}-checkbox`, children: _jsx("input", { type: "checkbox", className: `${classPrefix}-checkbox-input`, name: name, ref: inputRef, value: inputValue, onClick: () => {
                                            setInputValue(inputValue => +!inputValue);
                                            setFirstClick(true);
                                        } }) }), _jsx("span", { children: " I have agreed to and certify my compliance with HKUNFT 's Terms of Service" })] }) }) }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: firstClick && !inputValue &&
                        _jsx("div", { id: "therms_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, style: { marginTop: "-16px" }, children: "Please agree to the therms and conditions" }) }) })] }) }));
});
export const Recaptcha = React.forwardRef(({ notify }, ref) => {
    React.useImperativeHandle(ref, () => ({
        test
    }));
    const [verified, setVerified] = useState(false);
    const [firstClick, setFirstClick] = useState(false);
    const test = () => {
        if (!verified) {
            setFirstClick(true);
        }
        else {
            return true;
        }
    };
    const handleRecaptchaChange = (value) => {
        // console.log('reCAPTCHA value:', value);
        if (!firstClick) {
            setFirstClick(true);
        }
        if (value) {
            // notify(true)
            setVerified(true);
        }
        else {
            // notify(false)
            setVerified(false);
        }
    };
    return (_jsxs("div", { children: [_jsx(ReCAPTCHA, { sitekey: "6LfR_VgnAAAAAKh8wMaHfg7Sq65LiSgVOecDjiBU", onChange: handleRecaptchaChange }), _jsx("div", { style: { display: "flex", flexWrap: "nowrap" }, children: firstClick && !verified &&
                    _jsx("div", { id: "therms_help", children: _jsx("div", { className: `${classPrefix}-form-item-explain-error`, children: "Please complete the recaptcha" }) }) })] }));
});
export const Btnbar = ({ handleSubmit, handleCancel, title }) => {
    const handleClick = () => {
        handleSubmit();
    };
    // UserNameInput.
    return (_jsx("div", { className: `${classPrefix}-form-item`, children: _jsx("div", { className: `${classPrefix}-form-item-control`, children: _jsx("div", { className: `${classPrefix}-form-item-control-input`, children: _jsxs("div", { className: `${classPrefix}-form-item-control-input-content ${handleCancel ? "with-cancel-btn" : ""}`, children: [_jsx("div", { className: `${classPrefix}-form-item-control-btn ${classPrefix}-form-item-control-btn-lg`, onClick: handleClick, style: handleCancel && { width: "198px" }, children: _jsx("span", { children: title }) }), handleCancel &&
                            _jsx("div", { className: `${classPrefix}-form-item-control-btn
                                 ${classPrefix}-form-item-control-btn-lg
                                 ${classPrefix}-form-item-control-btn-df
                                 `, onClick: handleCancel, style: { width: "198px" }, children: _jsx("span", { children: "Cancel" }) })] }) }) }) }));
};
