import { Keccak } from "sha3";
function convertAddress(address) {
    try {
        address = address.toLowerCase().replace("0x", "");
        const keccak = new Keccak(256);
        keccak.update(address);
        const hash = keccak.digest("hex");
        let ret = "0x";
        for (let i = 0; i < address.length; i++) {
            if (parseInt(hash[i], 16) >= 8) {
                ret += address[i].toUpperCase();
            }
            else {
                ret += address[i];
            }
        }
        return ret;
    }
    catch (e) {
        return address;
    }
}
export function toChecksumAddress(address) {
    const addressArr = address.split(",");
    if (addressArr.length === 1) {
        return convertAddress(addressArr[0]);
    }
    else {
        let str = "";
        addressArr.forEach((item, index) => {
            str += convertAddress(item);
            if (index !== addressArr.length - 1)
                str += `
`;
        });
        return str;
    }
}
export function parseJSONWithNaNToNul(jsonString) {
    const updatedJson = jsonString.replace(/NaN/g, "null");
    // 将更新后的 JSON 字符串解析为 JavaScript 对象
    return JSON.parse(updatedJson);
}
//针对字符串"start_date:2023-10-11,end_date:2023-10-17"
export function getEndDate(text) {
    const regex = /end_date:(\d{4}-\d{2}-\d{2})/;
    const match = regex.exec(text || "");
    if (match && match[1]) {
        return match[1];
    }
    else {
        return undefined;
    }
}
//针对字符串格式"2023-03-13"
export function getWeekNumber(dateString) {
    const dateArray = dateString?.split("-")?.map(Number);
    const year = dateArray?.[0] || 0, month = dateArray?.[1] || 0, day = dateArray?.[2] || 0;
    const date = new Date(year, month - 1, day);
    date.setHours(0, 0, 0, 0);
    const firstDayOfYear = new Date(year, 0, 1);
    const daysToFirstMonday = (7 - firstDayOfYear.getDay()) % 7;
    const diff = date - firstDayOfYear + daysToFirstMonday * 24 * 60 * 60 * 1000;
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return {
        weekNumber,
        year
    };
}
function formatDecimalWithScientificNotation(num, fractionDigit) {
    const parts = /^([+-]?\d+(?:\.\d*))(?:[eE]([+-]?\d+))?$/.exec(num);
    if (parts) {
        const decimalPart = parseFloat(parts[1]).toFixed(fractionDigit);
        const roundedDecimal = parseFloat(decimalPart);
        const exponentPart = parts[2] || "0"; // 如果没有指数部分，默认为 0
        const formattedDecimalPart = roundedDecimal % 1 === 0 ? String(parseInt("" + roundedDecimal)) : decimalPart;
        const trimmedDecimalPart = formattedDecimalPart.includes(".") ? formattedDecimalPart.replace(/\.?0*$/, "") : formattedDecimalPart;
        return `${trimmedDecimalPart}e${exponentPart}`;
    }
    else {
        return num; // 格式不匹配的情况下返回原值
    }
}
export function formatDeciaml(num, fractionDigit) {
    if (typeof num === "number") {
        num = "" + num;
    }
    const scientificNotation = /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/.test(num);
    if (!scientificNotation) {
        let integerPart, decimalPart;
        let temp = num?.split(".");
        if (temp) {
            integerPart = temp[0];
            decimalPart = temp[1];
        }
        if (decimalPart) {
            // 如果有小数部分
            num = (+num).toFixed(fractionDigit);
            temp = num?.split(".");
            decimalPart = temp[1];
            let formattedDecimalPart = decimalPart;
            while (formattedDecimalPart.length < fractionDigit) {
                formattedDecimalPart += "0"; // 补足小数位数
            }
            const formattedNum = `${integerPart}.${formattedDecimalPart}`;
            return formattedNum;
        }
        else {
            return integerPart; // 如果没有小数部分，则直接返回整数部分
        }
    }
    else {
        //科学计数法，保留两位小数
        return formatDecimalWithScientificNotation(num, 2);
    }
}
export function fixedData(num, mode) {
    if (typeof num !== 'number')
        return num;
    if (mode === "data") {
        if (Math.abs(num) >= 1) {
            return formatDeciaml(num, 2);
        }
        else if (Math.abs(num) < 1) {
            return formatDeciaml(num, 4);
        }
    }
    else if (mode === "rate") {
        if (Math.abs(num) * 100 >= 1) {
            return formatDeciaml(num, 4);
        }
        else if (Math.abs(num) * 100 < 1) {
            return formatDeciaml(num, 6);
        }
    }
}
export function addFilterComma(num) {
    return num?.toString()?.replace(/^\d+/, (match) => {
        return match.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });
}
//矩阵翻转，时间复杂度是O(N*M)
export function transposeArray(arr) {
    const numRows = arr.length;
    const numCols = arr[0].length;
    // 创建一个新的数组以存储翻转后的结果
    const transposedArray = new Array(numCols);
    for (let i = 0; i < numCols; i++) {
        transposedArray[i] = new Array(numRows);
    }
    // 执行翻转操作
    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            transposedArray[j][i] = arr[i][j];
        }
    }
    return transposedArray;
}
export function transChartData(arr, choice) {
    let resArr = [];
    resArr.push(arr?.[0] || []);
    for (let i of choice) {
        resArr.push(arr?.[i + 1] || []);
    }
    return transposeArray(resArr);
}
export function generateTopCardVolumeDS(inputArr, xAxis, yAxis, ...args) {
    let output = [];
    let temp = [];
    Array.isArray(inputArr) && inputArr.forEach((item, index) => {
        temp = [];
        for (let key in item) {
            if (key === xAxis) {
                temp[0] = item[key];
            }
            else if (key === yAxis) {
                temp[1] = item[key];
            }
        }
        Array.isArray(args) && args.forEach(args_item => {
            temp.push(item[args_item]);
        });
        output.push(temp);
    });
    output.push(['Date', 'Volume']);
    return output.reverse();
}
export function convertTimestamp(timestamp) {
    if (!timestamp)
        return '';
    let date = new Date(timestamp * 1000); // 参数需要毫秒数，所以这里将秒数乘于 1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = String(date.getDate()).padStart(2, '0') + ' ';
    let h = String(date.getHours()).padStart(2, '0') + ':';
    let m = String(date.getMinutes()).padStart(2, '0') + ':';
    let s = String(date.getSeconds()).padStart(2, '0');
    return Y + M + D + h + m + s;
}
export const convertTime = (date) => {
    if (!date)
        return '';
    const originalDate = new Date(date);
    return `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;
};
